import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Login } from 'app/core/models/login';
import { UserSocialLogin } from 'app/core/models/UserSocialLogin';
import { LoginService } from 'app/core/services/login/login.service';
import { FlashMessagesUtilService } from 'app/core/services/util/flash-messages-util.service';
import { UrlUtilService } from 'app/core/services/util/url-util.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-claim-login',
  templateUrl: './claim-login.component.html',
  styleUrls: ['./claim-login.component.css'],
})
export class ClaimLoginComponent implements OnInit, OnDestroy {
  @Input() public lang: String;
  @Input() public userEmail: String;
  @Input() public diplomaType: String;
  @Input() public socialLogins: UserSocialLogin;
  @Input() public hasSocialLogin: boolean;
  @Input() public hasRegisteredSupportEmail: string;

  @Output() public loginSubmit: EventEmitter<String> = new EventEmitter<String>();

  public form: FormGroup;
  public loginData = new Login(
    environment.CLIENT_ID,
    environment.CLIENT_SECRET,
    environment.GRANT_TYPE_PASSWORD,
    '',
    '',
    '',
    ''
  );

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private urlUtilService: UrlUtilService,
    private flashMessagesUtilService: FlashMessagesUtilService,
  ) {}

  ngOnInit(): void {
    this.urlUtilService.customDomainRedirectToLogin();

    this.form = this.formBuilder.group({
      username: [
        this.userEmail,
        [
          Validators.required,
          Validators.pattern(/^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,9}))$/),
        ],
      ],
      password: ['', [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onLogin() {
    this.loginService
      .makeLogin(this.loginData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          data['user']
            ? this.loginService.login(data['user'], false)
            : this.initError('Incorrect Username or Password');
        },
        (err) => this.initError(err.error.message ?? '')
      );
  }

  private initError(message: string) {
    this.flashMessagesUtilService.flashMessages(message, 'danger');
  }

  get username() {
    return this.form.controls.username;
  }

  get password() {
    return this.form.controls.password;
  }
}
