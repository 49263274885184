<div class="social-actions">
  <app-diploma-share-button
    *ngFor="let button of socialShareButtons"
    [share]="this.shareData"
    [diploma]="diploma"
    [social]="button.name"
    [type]="button.type"
    (OpenImageSelect)="openImageSelect($event)"
  ></app-diploma-share-button>

  <ng-template #selectShareMethodModal let-d="dismiss">
    <app-diploma-share-dialog
      [diplomaHash]="diploma?.diploma_hash"
      [dismiss]="d"
      [social]="this.currentSocial"
      (selectEvent)="handleShare($event)">
    </app-diploma-share-dialog>
  </ng-template>
</div>
