<app-header></app-header>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center" *ngIf="data?.name else loadingPart">
    <article class="col-lg-9 col-md-8">
      <div class="user-details white-box margin-top-inverse-85">
        <figure class="image">
          <img src="{{ data?.image }}" alt="User Image" class="img-responsive">
        </figure>
        <div class="action font-13 text-right">
          <a routerLink="/{{ language }}/editProfile" class="link edit text-semibold">
            {{ 'Edit' | translate }}&nbsp;
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </a>
        </div>
        <h1 class="text-center margin-bottom-20">
          {{ data?.name }}
        </h1>
        <p *ngIf="data?.description" [innerHTML]="data?.description" class="text-center"></p>
        <div class="social-icons">
          <a href="{{ data?.facebook }}" target="_blank" rel="noopener noreferrer" *ngIf="data?.facebook" (click)="trackLink(data?.facebook)">
            <i class="fa fa-facebook facebook" aria-hidden="true"></i>
          </a>
          <a href="{{ data?.linkedin }}" target="_blank" rel="noopener noreferrer" *ngIf="data?.linkedin" (click)="trackLink(data?.linkedin)">
            <i class="fa fa-linkedin linkedin" aria-hidden="true"></i>
          </a>
          <a class="hidden" href="{{ data?.googleplus }}" target="_blank" rel="noopener noreferrer" *ngIf="data?.googleplus" (click)="trackLink(data?.googleplus)">
            <i class="fa fa-google-plus google-plus" aria-hidden="true"></i>
          </a>
          <a href="{{ data?.twitter }}" target="_blank" rel="noopener noreferrer" *ngIf="data?.twitter" (click)="trackLink(data?.twitter)">
            <i class="fa fa-twitter twitter" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div *ngIf="result">
        <div *ngIf="result?.claimedDiplomas || result?.unClaimedDiplomas else noDiplomaPart">
          <h3 class="margin-top-40 margin-bottom-20 text-center">
            {{ "/user_name/'s overview" | translate:{user_name: data?.name} }}:
          </h3>
          <div class="unclaimed-diploma diploma-details white-box" *ngFor="let result of result.unClaimedDiplomas">
            <div class="row">
              <div class="col-lg-7">
                  {{ "You have a new /term/ waiting for you from" | translate: { 'term': result.communication_term } }}<br>
                  <span class='organizationName'>{{ result.issued_by }}</span>
              </div>
              <div class="col-lg-5">
                  <a href="{{ result.claim_url }}?from=email_profile">
                    <button type="button">
                        <i class="fa fa-check" aria-hidden="true"></i>
                      {{ 'Get it now' | translate }}
                    </button>
                  </a>
              </div>
            </div>
          </div>
          <div class="diploma-details white-box" *ngFor="let result of result.claimedDiplomas">
            <div class="row">
              <div class="col-lg-3 align-self-center">
                <figure class="badge">
                  <img src="{{ result.diploma_image }}" alt="{{ result.diploma_name }}" class="img-responsive diploma-image">
                </figure>
              </div>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-6">
                    <div class="status" *ngIf="result.expired else otherStatus">
                      <span class="expired red-color">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        {{ 'Expired' | translate }}
                      </span>
                    </div>
                    <ng-template #otherStatus>
                      <div class="status">
                        <span class="public" *ngIf="result.status == 1">
                          <i class="fa fa-check" aria-hidden="true"></i>
                          {{ 'Visible to all' | translate }}
                        </span>
                        <span class="private" *ngIf="result.status == 2">
                          <i class="fa fa-lock" aria-hidden="true"></i>
                          <span *ngIf="result.has_special; else privateNoSpecial">
                            {{ 'Private. Visible to you and anyone with an active view shared by you' | translate }}
                          </span>
                          <ng-template #privateNoSpecial>
                            {{ 'Private & only visible to you' | translate }}
                          </ng-template>
                        </span>
                      </div>
                    </ng-template>
                  </div>
                  <div class="col-6 text-right">
                    <a routerLink="/{{ language }}/editDiplomaStatus/{{ result?.uhash }}/{{ result?.diploma_nice_name }}" class="link edit text-semibold">
                      {{ 'Edit Settings' | translate }}&nbsp;
                      <i class="fa fa-pencil" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <h4 class="margin-bottom-15">
                  {{ result.diploma_name }}
                </h4>
                <table class="no-border table table-sm margin-bottom-15">
                  <tr>
                    <td class="width-130">{{ 'Issued date' | translate }}:</td>
                    <td>{{ result.issue_date }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'Issued by' | translate }}:</td>
                    <td class='organizationName'>{{ result.issued_by }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'Issued to' | translate }}:</td>
                    <td>{{ result.issued_to }}</td>
                  </tr>
                  <tr *ngIf="result.expiry_date" class="{{ result.expired ? 'red-color' : '' }}">
                    <td>
                      <span *ngIf="!result.expired">{{ 'Expires' | translate }}</span>
                      <span *ngIf="result.expired">{{ 'Is Expired' | translate }}</span>:
                    </td>
                    <td>
                      <span>{{ result.expiry_date }}</span>
                    </td>
                  </tr>
                </table>
                <p class="no-margin">
                  <a routerLink="/{{ language }}/diploma/{{ result.uhash }}/{{ result.diploma_nice_name }}" class="link green-color text-semibold">
                    {{ 'See /term/' | translate: {'term': result.communication_term} }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="diploma-details white-box other-diplomas margin-top-40" *ngIf="result.otherDiplomas">
          {{ 'You have diplomas from other issuers. For security reasonsthese are not currently accessible since you logged in via specific issuer. If you want to access all diplomas please log out and log in using the general Diplomasafe login at /login_url/' | translate:{login_url :  this.loginUrl}  }}
        </div>
        <ng-template #noDiplomaPart>
          <div class="diploma-details white-box no-diplomas margin-top-40">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><br>
            {{ 'Sorry you do not have any diplomas.' | translate }}
          </div>
        </ng-template>
      </div>
      <div class="inside-loader" *ngIf="resultLoading">
        <div class="row justify-content-md-center">
          <div class="col-md-8 col-lg-6">
            <div class="white-box margin-top-inverse-85 loading-page text-center">
              <h1 class="margin-bottom-30 green-color">
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
                <span class="sr-only">{{ 'Loading' | translate }}...</span>
              </h1>
              <p>
                {{ 'Loading... Please Wait' | translate }}...
              </p>
            </div>
          </div>
        </div>
      </div>
      <div ></div>
    </article>
    <aside class="col-lg-3 col-md-4 hidden-sm-down text-center">
      <div class="diplomasafe">
        <figure class="hat-logo"></figure>
        <h4 class="margin-bottom-15 margin-top-15">
          {{ 'About /Diplomasafe/' | translate:{Diplomasafe: 'Diplomasafe'} }}?
        </h4>
        <p>
          {{ "/Diplomasafe/ is a platform for secure digital diplomas, issued by schools, universities and other institutions and training facilities working professionally with education. /Diplomasafe/ safeguards and prevent fraud for everyone in the diploma ecosystem - namely the issuers of diplomas, the receivers of diplomas and the verifiers of diplomas." | translate:{Diplomasafe: 'Diplomasafe'} }}
        </p>
        <p>
          {{ '/Diplomasafe/ - The proof of education' | translate: {Diplomasafe: 'Diplomasafe'}  }}
        </p>
      </div>
    </aside>
  </div>
  <ng-template #loadingPart>
    <div class="row justify-content-md-center">
      <div class="col-md-8 col-lg-6">
        <div class="white-box margin-top-inverse-85 loading-page text-center">
          <h1 class="margin-bottom-30 green-color">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
            <span class="sr-only">{{ 'Loading' | translate }}...</span>
          </h1>
          <p>
            {{ 'Loading... Please Wait' | translate }}...
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</main>
<app-footer></app-footer>
