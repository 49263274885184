<app-header></app-header>
<main class="container margin-bottom-70">
  <div *ngIf="data else loadingPart">
    <div class="row justify-content-md-center" *ngIf="!data?.error else errorPart">
      <article class="col-lg-9 col-md-8">
        <div class="user-details white-box margin-top-inverse-85">
          <figure class="image">
            <img src="{{ data?.image }}" alt="User Image" class="img-responsive">
          </figure>
          <h1 class="text-center margin-bottom-20">
            {{ data?.name }}
          </h1>
          <p [innerHTML]="data?.description" class="text-center"></p>
          <div class="social-icons">
            <a href="{{ data?.facebook }}" target="_blank" rel="noopener noreferrer" *ngIf="data?.facebook" (click)="trackLink(data?.facebook)">
              <i class="fa fa-facebook facebook" aria-hidden="true"></i>
            </a>
            <a href="{{ data?.linkedin }}" target="_blank" rel="noopener noreferrer" *ngIf="data?.linkedin" (click)="trackLink(data?.linkedin)">
              <i class="fa fa-linkedin linkedin" aria-hidden="true"></i>
            </a>
            <a href="{{ data?.googleplus }}" target="_blank" rel="noopener noreferrer" *ngIf="data?.googleplus" (click)="trackLink(data?.googleplus)" class="hidden">
              <i class="fa fa-google-plus google-plus" aria-hidden="true"></i>
            </a>
            <a href="{{ data?.twitter }}" target="_blank" rel="noopener noreferrer" *ngIf="data?.twitter" (click)="trackLink(data?.twitter)">
              <i class="fa fa-twitter twitter" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div *ngIf="result">
          <div *ngIf="result?.claimedDiplomas else noDiplomaPart">
            <h3 class="margin-top-40 margin-bottom-20 text-center">
              {{ "/user_name/'s overview" | translate:{user_name: data?.name} }}:
            </h3>
            <div *ngFor="let result of result.claimedDiplomas" class="diploma-details">
              <div class="white-box" *ngIf="result.status == 1">
                <div class="row">
                  <div class="col-lg-3 align-self-center">
                    <figure class="badge">
                      <img src="{{ result.diploma_image }}" alt="{{ result.diploma_name }}" class="img-responsive diploma-image">
                    </figure>
                  </div>
                  <div class="col-lg-9">
                    <h4 class="margin-bottom-15">
                      {{ result.diploma_name }}
                    </h4>
                    <table class="no-border table table-sm margin-bottom-15">
                      <tr>
                        <td class="width-130">{{ 'Issued date' | translate }}:</td>
                        <td>{{ result.issue_date }}</td>
                      </tr>
                      <tr>
                        <td>{{ 'Issued by'  | translate }}:</td>
                        <td>{{ result.issued_by }}</td>
                      </tr>
                      <tr *ngIf="result.expiry_date" class="{{ result.expired ? 'red-color' : '' }}">
                        <td>
                          <span *ngIf="!result.expired">{{ 'Expires' | translate }}</span>
                          <span *ngIf="result.expired">{{ 'Is Expired' | translate }}</span>:
                        </td>
                        <td>
                          <span>{{ result.expiry_date }}</span>
                        </td>
                      </tr>
                    </table>
                    <p class="no-margin">
                      <a routerLink="/{{ language }}/diploma/{{ result.uhash }}/{{ result.diploma_nice_name }}" class="link green-color text-semibold">
                        {{ 'See Diploma' | translate }}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noDiplomaPart>
            <div class="diploma-details white-box no-diplomas margin-top-40">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><br>
              {{ "Sorry, /user_name/ does not have any diploma." | translate:{user_name: data?.name} }}
            </div>
          </ng-template>
        </div>
      </article>
      <aside class="col-lg-3 col-md-4 hidden-sm-down">
        <div class="diplomasafe text-center">
          <figure class="hat-logo"></figure>
          <h4 class="margin-bottom-15 margin-top-15">
            {{ 'About /Diplomasafe/' | translate:{Diplomasafe: 'Diplomasafe'} }}?
          </h4>
          <p>
            {{ "/Diplomasafe/ is a platform for secure digital diplomas, issued by schools, universities and other institutions and training facilities working professionally with education. /Diplomasafe/ safeguards and prevent fraud for everyone in the diploma ecosystem - namely the issuers of diplomas, the receivers of diplomas and the verifiers of diplomas." | translate:{Diplomasafe: 'Diplomasafe'} }}
            </p>
            <p>
              {{ '/Diplomasafe/ - The proof of education' | translate: {Diplomasafe: 'Diplomasafe'}  }}
            </p>
        </div>
      </aside>
    </div>
    <ng-template #errorPart>
      <div class="row justify-content-md-center">
        <div class="col-md-8 col-lg-6">
          <div class="white-box margin-top-inverse-85 text-center error-page">
            <h1 class="margin-bottom-30 red-color">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'Error' | translate }}
            </h1>
            <p class="margin-bottom-30">
              {{ data?.message.errormsg | translate }}
            </p>
            <a class="btn btn-success text-uppercase" routerLink="/">{{ 'Go Back to the Homepage' | translate }}</a>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #loadingPart>
    <div class="row justify-content-md-center">
      <div class="col-md-8 col-lg-6">
        <div class="white-box margin-top-inverse-85 loading-page text-center">
          <h1 class="margin-bottom-30 green-color">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
            <span class="sr-only">{{ 'Loading' | translate }}...</span>
          </h1>
          <p>
            {{ 'Loading' | translate }}... {{ 'Please Wait' | translate }}...
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</main>
<app-footer></app-footer>
