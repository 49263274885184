
<app-header [diplomaDesign]="this.diplomaDesign" [defaultFont]="false" [customSuportLink]="data?.organization.customSupportPageLink" [diploma]='data?.diploma'></app-header>

<flash-messages></flash-messages>
<main class="container margin-bottom-70">
  <div class="row justify-content-md-center" *ngIf="data?.recipient.recipient_name else loadingPart">
    <article class="col-lg-9 col-md-8">
      <div class="diploma-details white-box margin-top-inverse-85">
        <div class="row">
          <div class="col-lg-4 badge-column">
            <figure class="badge">
              <img src="{{ data?.diploma.diploma_image }}" alt="{{ data?.diploma.diploma_name }}" class="img-responsive diploma-image">
            </figure>
          </div>
          <div class="col-lg-8">
            <div class="row action font-13" *ngIf="userDetails">
              <ng-container *ngIf="data?.diploma.same_user">
                <div class="col-6">
                  <div class="status red-color expired" *ngIf="data?.diploma.expired else otherStatus">
                    <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
                    {{ 'Expired' | translate }}
                  </div>
                  <ng-template #otherStatus>
                    <div class="status public" *ngIf="data?.diploma.diploma_status == 1">
                        <i class="fa fa-check" aria-hidden="true"></i>&nbsp;
                        {{ 'Visible to all' | translate }}
                    </div>
                    <div class="status private" *ngIf="data?.diploma.diploma_status == 2">
                      <i class="fa fa-lock" aria-hidden="true"></i>&nbsp;
                      <span *ngIf="data?.diploma.has_special; else privateNoSpecial">
                        {{ 'Private. Visible to you and anyone with an active view shared by you' | translate }}
                      </span>
                      <ng-template #privateNoSpecial>
                        {{ 'Private & only visible to you' | translate }}
                      </ng-template>
                    </div>
                  </ng-template>
                </div>
                <div class="col-6 text-right">
                  <a routerLink="/{{ language }}/editDiplomaStatus/{{ data?.diploma.diploma_hash }}/{{ data?.diploma.diploma_nice_name }}" class="link edit text-semibold">
                    {{ 'Edit Settings' | translate }}&nbsp;
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </a>
                </div>
              </ng-container>
            </div>
            <h1 class="margin-top-15" *ngIf="data?.diploma.template_type">
              {{ data?.diploma.template_type }}
            </h1>
            <h2 class="margin-top-15">
              {{ data?.diploma.diploma_name }}
            </h2>
            <table class="no-border table table-sm no-margin">
              <tr>
                <td style="width: 260px !important;">{{ 'Issued to' | translate }}:</td>
                <td>{{ data?.recipient.recipient_name }}</td>
              </tr>
              <tr>
                <td style="width: 260px !important;">{{ 'Issued date' | translate }}:</td>
                <td>{{ data?.diploma.issue_date | amLocale:language | amDateFormat:'LL' }}</td>
              </tr>
              <tr>
                <td style="width: 260px !important;">{{ 'Issued by'  | translate }}:</td>
                <td class='orgName'>{{ data?.organization.name }}</td>
              </tr>
              <tr *ngIf="data?.diploma.expiry_date">
                <td style="width: 260px !important;">{{ 'Expires' | translate }}:</td>
                <td>
                  <span>{{ data?.diploma.expiry_date | amLocale:language | amDateFormat:'LL' }}</span>
                </td>
              </tr>
              <tr *ngIf="data?.diploma.pno_visibility">
                <td style="width: 260px !important;">{{ data?.diploma.pno_label }}:</td>
                <td>({{ 'Hidden' | translate }})</td>
              </tr>
            </table>
            <table class="no-border table table-sm no-margin" *ngIf="data?.diploma.fieldsData">
                <!-- TODO SG: remove after implementation of table type support -->
                <ng-container *ngFor="let result of data?.diploma.fieldsData">
                    <tr *ngIf="result.type !== 'table'">
                        <td style="width: 260px !important;">{{ result.name }}:</td>
                        <td>
                            <ng-container *ngIf="result.type == 'link'">
                                <a href="{{ result.value }}" target="_blank" rel="noopener noreferrer"
                                   class="link text-semibold">
                                    {{ result.value }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="result.type == 'image'">
                                <img src="{{ result.value }}" class="custom-field-image"/>
                            </ng-container>
                            <ng-container *ngIf="result.type == 'textarea'">
                                <p [innerHTML]="result.value"></p>
                            </ng-container>
                            <ng-container
                                    *ngIf="result.type !== 'link' && result.type !== 'image' && result.type !== 'textarea'">
                                <span class="break-word">{{ result.value }}</span></ng-container>
                        </td>
                    </tr>
                </ng-container>
            </table>
            <br >
            <p *ngIf="data?.diploma.diploma_description" [innerHTML]="data?.diploma.diploma_description"></p>

              <ng-container *ngFor="let field of data?.diploma.fieldsData">
                  <table *ngIf="showTable(field) else plainText" class="table">
                      <thead *ngIf="field.settings.settings.visible">
                      <tr>
                          <th scope="col" *ngFor="let col of field.settings.cols" [ngStyle]="{'text-align': col['alignment']}">
                              {{ col['visible'] ? col['colName'] : ''}}
                          </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let row of field.value | keyvalue: fieldsOrder">
                          <td *ngFor="let colData of row.value; let i = index;"
                              [innerHtml]="colData" [ngStyle]="{'text-align': field.settings.cols[i]['alignment']}">
                          </td>
                      </tr>
                      </tbody>
                  </table>
                  <ng-template #plainText>
                    <div *ngIf="showTableReplacement(field)" class="table-replacement-text">
                        <span>{{field.value}}</span>
                    </div>
                </ng-template>
              </ng-container>

            <p class="margin-top-10">
              <a href="{{ data?.diploma.template_link }}" class="link text-semibold" *ngIf="data?.diploma.template_link" target="_blank" rel="noopener noreferrer" (click)="trackLink(data?.diploma.template_link, data?.diploma.template_id)">
                {{ 'Read more' | translate }}&nbsp;
                <i class="fa fa-external-link" aria-hidden="true"></i>
              </a>
            </p>
          </div>
          <div id="verification">
            <a href="javascript:void(0)" class="verified hidden-md-down" (click)="openModel(verifiedModal, 'verifiedModal')" *ngIf="data?.recipient.recipient_name">
              <h4>{{ 'Verified' | translate }}</h4>
              <p>
                {{ 'What does it mean?' | translate }}
              </p>
              <div class="check">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- Pathways widgets -->
      <ng-container *ngIf="data?.diploma.pathways_enabled && data?.diploma.has_active_goals && data?.diploma.display_goals_widget">
        <app-pathway-widget-base
          [diplomaHash]="data.diploma.diploma_hash"
        ></app-pathway-widget-base>
      </ng-container>
      <!-- Pathways widgets END -->

      <h3 class="margin-top-40 margin-bottom-20 text-center orgName">
        {{ 'About /organization_name/' | translate:{organization_name: data?.organization.name_about} }}:
      </h3>
      <div class="company-details white-box">
        <div class="row">
          <div class="col-lg-4 align-self-top" *ngIf="data?.organization.image">
            <figure class="image">
              <img src="{{ data?.organization.image }}" alt="Issuer Logo" class="org-img-responsive" />
            </figure>
          </div>
          <div class="col-lg-{{ (data?.organization.image) ? '8' : '12' }}">
            <p *ngIf="data?.organization.description" [innerHTML]="data?.organization.description"></p>
            <p class="social-follow" *ngIf="data?.organization.web || data?.organization.facebook || data?.organization.linkedin || data?.organization.twitter || data?.organization.instagram">
              <span class="font-18 text-extralight">
                {{ 'Follow Us on' | translate }}: &nbsp;
              </span>
              <a class="home" href="{{ data?.organization.web }}" *ngIf="data?.organization.web" target="_blank" rel="noopener noreferrer" (click)="trackLink(data?.organization.web, null)">
                <i class="fa fa-home" aria-hidden="true"></i>
              </a>
              <a class="facebook" href="{{ data?.organization.facebook }}" *ngIf="data?.organization.facebook" target="_blank" rel="noopener noreferrer" (click)="trackLink(data?.organization.facebook, null)">
                <i class="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a class="linkedin" href="{{ data?.organization.linkedin }}" *ngIf="data?.organization.linkedin" target="_blank" rel="noopener noreferrer" (click)="trackLink(data?.organization.linkedin, null)">
                <i class="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a class="twitter" href="{{ data?.organization.twitter }}" *ngIf="data?.organization.twitter" target="_blank" rel="noopener noreferrer" (click)="trackLink(data?.organization.twitter, null)">
                <i class="fa fa-twitter" aria-hidden="true"></i>
              </a>
              <a class="instagram" href="{{ data?.organization.instagram }}" *ngIf="data?.organization.instagram" target="_blank" rel="noopener noreferrer" (click)="trackLink(data?.organization.instagram, null)">
              </a>
            </p>
          </div>
        </div>
      </div>
    </article>
    <aside class="col-lg-3 col-md-4">
      <div *ngIf="data?.diploma.same_user else publicView">
        <div class="box with-arrow green-box text-center"
        [style.background]="diplomaDesign.custom_colors && diplomaDesign.box_1_color
          ? diplomaDesign.box_1_color
          : ''"
        [style.color]="diplomaDesign.custom_colors && diplomaDesign.box_1_text_color
          ? diplomaDesign.box_1_text_color
          : ''"
        [className]="diplomaDesign.custom_colors && diplomaDesign.box_1_color ? 'box text-center no-arrow' : 'box with-arrow green-box text-center'">
          <figure class="image div-shadow">
            <img src="{{ data?.recipient.recipient_image }}" alt="User Image" class="img-responsive">
          </figure>
          <h4 [style.color]="diplomaDesign.custom_colors && diplomaDesign.box_1_text_color ? diplomaDesign.box_1_text_color : ''"
            class="margin-bottom-15 margin-top-10 white-color-ni">
            {{ 'Hi, /recipient_name/' | translate:{recipient_name: data?.recipient.recipient_name} }}
          </h4>
          <p>
            {{ 'Congratulations on your digital /term/.' | translate: {term: data?.diploma.template_term } }}<br>
            {{
              data.diploma?.with_some_buttons
                ? ('Now you can easily share the good news with your friends, family and colleagues.' | translate)
                :  ''
            }}
          </p>
        </div>
        <div class="social-actions">
          <!-- Social share -->
          <app-diploma-share-button-group
            *ngIf="data.diploma?.with_some_buttons"
            [diploma]="data?.diploma"
            [linkedinDirectShare]="data?.organization.linkedinDirectShare"
            [userLinkedinId]="data?.recipient.linkedin_id"
          ></app-diploma-share-button-group>
          <!-- End Social share -->

          <a href="javascript:void(0)" class="verified hidden-md-down text-center" (click)="openModel(whatIsShared, 'verifiedModal')" *ngIf="this.specialFields?.length > 0">
            <p class="margin-top-10">
              {{'What is shared ?' | translate}} <br>
              {{ 'Click here' | translate}}
            </p>
          </a>
        </div>
        <div class="other-options text-center">
          <h4 class="margin-bottom-10">
            {{  data.diploma?.with_some_buttons ? 'Other options' : 'Options' | translate }}:
          </h4>
          <p>
            {{ 'You can also download a PDF or share the URL to this diploma.' | translate }}
          </p>
          <div class="social-actions">
            <a routerLink="/{{ language }}/myDiplomaPDF/{{ data.diploma.diploma_hash }}/{{ data.diploma.diploma_id }}/generatePDF" target="_blank" rel="noopener noreferrer" *ngIf="!data?.diploma.pno_has_value">
              <div class="social-login-btn pdf">
                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;
                {{ 'Download PDF' | translate }}
              </div>
            </a>
            <a routerLink="/{{ language }}/dip-other-formats/{{ data.diploma.diploma_hash }}/{{data?.recipient.recipient_nice_name}}" rel="noopener noreferrer" *ngIf="data?.diploma.other_formats">
              <div class="social-login-btn other-formats">
                <i class="fa fa-download" aria-hidden="true"></i>&nbsp;
                {{ 'Other Formats' | translate }}
              </div>
            </a>
            <a href="javascript:void(0)" class="downloadPdf hidden-md-down" (click)="openModel(downloadPdfModal, 'downloadPdfModal')" *ngIf="data?.diploma.pno_has_value">
              <div class="social-login-btn pdf">
                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;
                {{ 'Download PDF' | translate }}
              </div>
            </a>
            <a
              *ngIf="data.diploma?.with_some_buttons"
              (click)="addToSocialProfile('LinkedinProfile', data)"
            >
              <div class="social-login-btn linkedin">
                <i class="fa fa-linkedin" aria-hidden="true"></i>&nbsp;
                {{ 'Add to Profile' | translate }}
              </div>
            </a>
            <a
              *ngIf="this.specialFields?.length === 0 && this.diplomaAccessLevel?.access_level == 0; else elseBlock"
              (click)="this.diplomaService.copyToClipboard(this.currentURL(data.diploma.diploma_custom_domain), this.successful, this.unsuccessful)"
              href="javascript:void(0)"
            >
              <div class="social-login-btn email">
                <i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;
                {{ 'Copy/share URL' | translate }}
              </div>
            </a>
            <ng-template #elseBlock>
              <a routerLink="/{{language}}/diploma-share-view/{{data.diploma.diploma_hash}}">
                <div class="social-login-btn email">
                  <i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;
                  {{ 'Copy/share URL' | translate }}
                </div>
              </a>
            </ng-template>
            <a routerLink="/{{ language }}/editProfile" [queryParams]="{tab: 1}" *ngIf="data.diploma.has_special">
                <div class="social-login-btn email">
                    <i class="fa fa-share" aria-hidden="true"></i>
                    {{ 'Manage Views' | translate }}
                </div>
            </a>

            <!-- Custom Buttons -->
            <app-custom-button-group
              *ngIf="data?.organization.enabledCustomButtons"
              [templateId]="data?.diploma.template_id"
              [diplomaHash]="data?.diploma.diploma_hash"
            ></app-custom-button-group>
            <!-- End Custom Buttons -->
          </div>
          <a href="#" class="reset green-color text-semibold margin-top-30 hidden">
            {{ 'See Other Options' | translate }}
          </a>
        </div>
      </div>
      <ng-template #publicView>
        <div class="box white-box text-center login-box" *ngIf='!userDetails'>
          {{ 'Is this your /template_type/?' | translate: {template_type: data?.diploma.template_term} }} <br/> {{ 'Log in to share or download' | translate }}
        </div>
        <app-template-information
                [templateId]="data?.diploma.template_id"
                [viewType]="1"
                [trackLink]="trackLink"
                [description]="data?.diploma.diploma_description"
                [templateLink]="data?.diploma.template_link"
                [diplomaName]="data?.diploma.diploma_name"
                [diplomaDesign]="diplomaDesign"
                [learnMoreFunction]="data?.diploma.learn_more_function"
                [organizationImage]="data?.organization.image"
                [openModel]="openModel">
            </app-template-information>
        <a href="{{ data?.organization.web }}" class="link" target="_blank" rel="noopener noreferrer" (click)="trackLink(data?.organization.web, data?.diploma.template_id)">
          <div class="box blue-box text-center div-shadow margin-top-20" *ngIf="data?.organization.web"
              [style.background]="diplomaDesign.custom_colors && diplomaDesign.organization_box_color
                ? diplomaDesign.organization_box_color
                : ''"
              [style.color]="diplomaDesign.custom_colors && diplomaDesign.organization_box_text_color
                ? diplomaDesign.organization_box_text_color
                : ''">
            <h4 class="white-color-ni margin-bottom-15"
              [style.color]="diplomaDesign.custom_colors && diplomaDesign.organization_box_text_color
                ? diplomaDesign.organization_box_text_color
                : '#fff'">
              {{ 'Want to know more about /organization_name/' | translate:{organization_name: data?.organization.name_learn_more} }}?
            </h4>
            <p class="link text-semibold">
              {{ 'Read more' | translate }}
              <i class="fa fa-external-link" aria-hidden="true"></i>
            </p>
          </div>
        </a>
        <div class="box validation-box text-center" *ngIf="data?.diploma.pno_label" (click)="openModel(validationModal, 'validationModal')">
          <h4 class="margin-bottom-15 margin-top-10 white-color">
            {{ 'Validate' | translate }}
          </h4>
          <p>{{ data?.diploma.pno_label }}</p>
          <a href="javascript:void(0)" class="validation hidden-md-down" *ngIf="data?.diploma.pno_label">
            <p>
              {{ 'Click here' | translate }}
            </p>
          </a>
        </div>
      </ng-template>
    </aside>
  </div>
  <ng-template #loadingPart>
    <div class="row justify-content-md-center">
      <ng-container *ngIf="((!this.special && this.withAccessLevel) || this.withGoalSelection) else loading">
      <app-two-factor-authentication-base
        *ngIf="!this.specialShare && this.withAccessLevel else withGoalSelectionRef"
        class="white-box margin-top-inverse-85"
        [sameUser]="this.diplomaAccessLevel?.same_user"
        [has2faCode]="this.diplomaAccessLevel?.has_2fa_code"
        [templateType]="this.diplomaAccessLevel?.template_type"
        [orgainzationImage]="this.diplomaAccessLevel?.org_image"
        (isValid)="handleAccessLevel($event)"
        #accessLevelPrompt
      ></app-two-factor-authentication-base>
      <ng-template #withGoalSelectionRef>
      <ng-container
        *ngIf="this.withGoalSelection else loading"
      >
        <app-pathway-settings-base
          class="white-box margin-top-inverse-85"
          [diplomaHash]="this.params.uh"
          (onSave)="handlePathwaySettingsUpdate($event)"
        ></app-pathway-settings-base>
      </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #loading>
        <div class="col-md-8 col-lg-6">
          <div class="white-box margin-top-inverse-85 loading-page text-center">
            <h1 class="margin-bottom-30 green-color">
              <i class="fa fa-spinner fa-pulse fa-3x fa-fw green-cl"></i>
              <span class="sr-only">{{ 'Loading' | translate }}...</span>
            </h1>
            <p *ngIf="this.shareViewLoading | async else genericLoading">
              {{ 'Preparing the View' | translate }}...
            </p>
            <ng-template #genericLoading>
                {{ 'Loading' | translate }}... {{ 'Please Wait' | translate }}...
            </ng-template>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</main>
<app-footer [diplomaDesign]="this.diplomaDesign" [customSuportLink]="data?.organization.customSupportPageLink"></app-footer>
<ng-template #verifiedModal let-c="close" let-d="dismiss">
  <app-verified-modal [data]="data" [language]="language" [dismiss]="d"></app-verified-modal>
</ng-template>
<!-- Adds add to profile modal. Kept for future reference.
  <ng-template #addToProfileModal let-c="close" let-d="dismiss">
  <app-diploma-add-to-profile
    [dismiss]="d"
    [cancel]="c"
    [successful]="this.successful"
    [unsuccessful]="this.unsuccessful"
    [language]="this.language"
    [data]="data"
    [currentURL]="this.currentURL">
  </app-diploma-add-to-profile>
</ng-template> -->

<ng-template #validationModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h2 class="modal-title" *ngIf="identifierData">
      {{ 'Validate Personal Identifier' | translate }}
    </h2>
    <div *ngIf="value?.matched" class="text-center">
      <i class="fa fa-check" aria-hidden="true"></i>
      <h2 class="modal-title">
        {{ value?.matched }}
      </h2>
    </div>
    <div *ngIf="value?.mis_matched" class="text-center">
      <i class="fa fa-times" aria-hidden="true"></i>
      <h2 class="modal-title">
        {{ value?.mis_matched }}
      </h2>
    </div>
    <div *ngIf="value?.not_allowed" class="text-center">
      <i class="fa fa-exclamation" aria-hidden="true"></i>
      <h2 class="modal-title">
        {{ value?.not_allowed }}
      </h2>
    </div>
  </div>
  <div class="modal-body">
    <div class="container">
      <div *ngIf="identifierData">
        <mat-form-field>
          <input matInput type="text" placeholder="{{  data.diploma.pno_help_text }}" [(ngModel)]="identifierValue">
        </mat-form-field>
        <div>
          <button type="submit" class="btn btn-success" (click)="validateIdentifier(data?.diploma.diploma_hash, data?.diploma.diploma_nice_name, this.identifierValue)">
          {{ 'Next' | translate }}
          </button>
          <button class="reset" type="button" class="btn btn-default"  (click)="d('Cross click')">
            {{ 'Cancel' | translate }}
          </button>
        </div>
      </div>
      <button class="reset" type="button" class="btn btn-default"  (click)="d('Cross click'); value = null;identifierData=true;" *ngIf="value?.matched || value?.mis_matched || value?.not_allowed">
        {{ 'OK' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #downloadPdfModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h2 class="modal-title" *ngIf="identifierData">
      {{ 'Validate Personal Identifier' | translate }}
    </h2>
    <div *ngIf="value?.matched" class="text-center">
      <i class="fa fa-check" aria-hidden="true"></i>
      <h2 class="modal-title">
        {{ value?.matched }}
      </h2>
    </div>
    <div *ngIf="value?.mis_matched" class="text-center">
      <i class="fa fa-times" aria-hidden="true"></i>
      <h2 class="modal-title">
        {{ value?.mis_matched }}
      </h2>
    </div>
    <div *ngIf="value?.not_allowed" class="text-center">
      <i class="fa fa-exclamation" aria-hidden="true"></i>
      <h2 class="modal-title">
        {{ value?.not_allowed }}
      </h2>
    </div>
  </div>
  <div class="modal-body">
    <div class="container">
      <div *ngIf="identifierData">
        <mat-form-field>
          <input matInput type="text" placeholder="{{ data.diploma.pno_help_text }}" [(ngModel)]="identifierValue">
        </mat-form-field>
        <div class="button-group">
          <button type="submit" class="btn btn-success" (click)="validateIdentifier(data?.diploma.diploma_hash, data?.diploma?.diploma_nice_name, identifierValue)">
          {{ 'Next' | translate }}
          </button>
          <button class="reset" type="button" class="btn btn-default"  (click)="d('Cross click')">
            {{ 'Cancel' | translate }}
          </button>
        </div>
      </div>
      <div class="button-group">
        <a
          mat-raised-button
          *ngIf="value?.matched"
          routerLink="/{{ language }}/myDiplomaPDF/{{ data.diploma.diploma_hash }}/{{ data.diploma.diploma_id }}/{{ value.value }}/generatePDF"
          target="_blank"
          class="btn btn-light"
          rel="noopener noreferrer"
        >
          <div class="social-login-btn pdf">
            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;
            {{ 'Download PDF' | translate }}
          </div>
        </a>
        <button
          mat-flat-button
          class="reset"
          type="button"
          class="btn btn-secondary"
          (click)="d('Cross click');value = null; identifierData = true;"
          *ngIf="value?.matched || value?.mis_matched || value?.not_allowed">
          {{ 'Cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #whatIsShared let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h1 class="modal-title green-color">
      {{ 'What is shared' | translate }}
    </h1>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{ 'When sharing your diploma on social medias, viewers will be able to access your diploma including the diploma data as you see them except for the following data, which will be hidden:' | translate}}
    </p>
    <ul>
      <li *ngFor="let field of this.specialFields">
          {{ field }}
      </li>
    </ul>
    <p>
      {{ "If you want to share a password protected version where the more data is visible, you can use the 'Copy/share URL'-button." | translate }}
    </p>
  </div>
</ng-template>
<app-share-view-password-prompt (passwordCheck)="handlePassword($event)" #passwordModal></app-share-view-password-prompt>
