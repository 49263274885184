import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-diploma-share-button',
  templateUrl: './diploma-share-button.component.html',
  styleUrls: ['./diploma-share-button.component.css'],
})
export class DiplomaShareButtonComponent implements OnInit {
  @Input() public share: any;
  @Input() public diploma: any;  
  @Input() public social: string;
  @Input() public type: string;

  @Output() public OpenImageSelect = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  public openImageSelect(): void {
    this.OpenImageSelect.emit(this.type);
  }

  public shareData(): void {
    this.share(this.type);
  }
}
