import { Component } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { PiwikService } from './core/services/common-service/piwik.service';
import { environment } from '../environments/environment';

import { filter, map, mergeMap } from 'rxjs/operators';
import { MetaDataService } from './core/services/seo/meta-data.service';


@Component({
  providers: [PiwikService],
  selector: 'app-main',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  private langVal: any;
  private params: any = this.activatedRoute.snapshot.params;

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private piwik: PiwikService,
    private metaDataService: MetaDataService
  ) {
    // initialize Piwik
    if (environment.PIWIK_SITE_ID) {
      piwik.init(environment.PIWIK_SITE_ID, environment.PIWIK_URL);
    }
  }

  public ngOnInit() {

    if (this.cookieService.get('langVal')) {
      this.langVal = this.cookieService.get('langVal');
    } else {
      this.langVal = environment.DEFAULT_LANGUAGE;
    }
    this.translateService.setDefaultLang(this.langVal);

    this.setSeoAndMetaTags();

    this.generateBrowserSession();

    this.appendCookieBot();

    // TODO: for now this code needs to be commented
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     if (!((<any>window).Cookiebot?.consent?.marketing && (<any>window).Cookiebot?.consent?.preferences && (<any>window).Cookiebot?.consent?.statistics)) {
    //       (<any>window).Cookiebot?.renew();
    //     }
    //   }
    // });

  }

  get isDemo() {
    if (environment.APP_DEMO) {
      return 'demo';
    } else {
      return '';
    }
  }

  public generateBrowserSession() {
    try {
      if (!localStorage.getItem('browserSession')) {
        const now: any = Date.now() + Math.random();
        localStorage.removeItem('browserSession');
        localStorage.setItem('browserSession', now);
      }
    } catch (error) {
      return throwError(error);
    }
  }

  /**
   *  Set metadata to page
   *
   * @private
   */
  private setSeoAndMetaTags() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.getChild(this.activatedRoute)),
      mergeMap(route => route.data)
    ).subscribe((routeData: Data) => {

      // og:lang language code format is language_TERRITORY - we replace - with _ to match the format
      const currentLang: string = this.translateService.defaultLang.replace('-', '_');

      this.metaDataService.setMetaData(window.location.href, currentLang, routeData?.metaData, routeData?.robotsNoIndex);
    });
  }

  /**
   * Recursively traverse through the ActivatedRoute tree to get to the current route
   *
   * @param activatedRoute
   */
  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

  appendCookieBot() {

    // until migration to new spa  cookiebot id will be hardcoded
    if (environment.APP_URL === 'https://app.diplomasafe.com') {
      const script = document.createElement('script');
      script.src = 'https://consent.cookiebot.com/uc.js';
      script.type = 'text/javascript';
      script.async = true;
      script.setAttribute('data-cbid', 'f88c8ede-edd8-44e7-aca9-6eb4ecfb79b1');

      document.body.appendChild(script);
    }
  }
}
