import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TwoFactorAuthentication } from "../../../../core/models/two-factor-authentication";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TwoFactorAuthenticationServise } from "../../../../core/services/two-factor-authentication/two-factor-authentication.service";

@Component({
    providers: [TwoFactorAuthenticationServise],
    selector: 'app-two-factor-authentication-prompt',
    templateUrl: './two-factor-authentication-prompt.component.html',
    styleUrls: ['./two-factor-authentication-prompt.component.css'],
})
export class TwoFactorAuthenticationPromptComponent implements OnInit, OnDestroy {
    @Input() public enabled2FA: boolean;
    @Input() public templateType: string;
    @Input() public isEditProfile: boolean;
    @Input() public orgainzationImage: string;
    @Input() private userHash: string;

    @Output() public isValid = new EventEmitter<boolean>();
    @Output() public twoFactorAuth = new EventEmitter<boolean>();
    
    @ViewChild('modalTwoFactor',{static: false}) modalTwoFactor: any;

    public form: FormGroup;
    private tries: number = 0;
    private modalRef: MatDialogRef<any>;
    private params = this.activatedRoute.snapshot.params;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private modal: MatDialog,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private twoFactorAuthenticationServise: TwoFactorAuthenticationServise
    ) { }

    ngOnInit() {
        this.form = this.formBuilder.group({
            secret: ['', [Validators.required]],
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public verifyTwoFactorAuthenticationCode(): void {
        const session = localStorage.getItem('browserSession');
        this.twoFactorAuthenticationServise
          .verifyCode(this.userHash, this.secret.value, session)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            (res) => {
              this.initData(res);

              // TODO : add user tries
              // this.tries = res['count'];
              // if (this.tries > 3) {
              //     this.secret.setErrors({toMenyTries: true});
              // } else {
              //     this.initData(res);
              // }
            },
            () => {
              this.twoFactorAuthenticationServise.handleError(this.params.lang);
            }
          );
    }

    public initData(twoFactorAuth: TwoFactorAuthentication): void {
        if(!twoFactorAuth.data.isValid && this.tries < 3) {
          this.secret.setErrors({'incorrect': true});
          localStorage.removeItem('currentUser2FA');
        } else if (this.isEditProfile) {            
            this.twoFactorAuthenticationServise.updateTwoFactorAuthentication(this.userHash, this.enabled2FA, this.userDetails.accessToken)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                    if(!this.enabled2FA){
                        localStorage.removeItem('currentUser2FA');
                    }
                    this.closeModal(false);
                },
                () => {
                    this.twoFactorAuthenticationServise.handleError(this.params.lang, 'Permissions denied to view diploma', true);
                });
        } else {
            this.twoFactorAuthenticationServise.setToken(twoFactorAuth.data.token);
            this.emitIsValid(twoFactorAuth.data.isValid);
            this.closeModal(false);
        }
    }

    public getErrorMessage() {
        return this.secret.hasError('required')
            ? 'You must enter a value'
            : 'Invalid code';
    }

    public emitIsValid(isValid: boolean) {
      this.isValid.emit(isValid);
    }

    public changeTwoFactorAuthentication($event: boolean) {
        this.isValid.emit($event);
    }

    public openCodeVerification(uHash: string) {
        this.userHash = uHash;

        this.modalRef = this.modal.open(this.modalTwoFactor, {
            restoreFocus: false,
            disableClose: true,
            hasBackdrop: true,
            width: '500px',
        });
    }

    public closeModal(closeButton: boolean) {
        this.modalRef.close();

        // remove input values
        this.form.reset();

        if (closeButton) {
            this.changeTwoFactorAuthentication(!this.enabled2FA);
        }
    }

    get userDetails() {
        if (localStorage.getItem('currentUser')) {
            const userDetail = JSON.parse(localStorage.getItem('currentUser'));
            if (userDetail.accessToken) {
                return userDetail;
            }
        }
        return false;
    }

    get secret() {
        return this.form.controls.secret;
    }
}
