<button *ngIf="!diploma.diploma_share_choice; else inChoice" (click)="shareData()" class="social-login-btn {{ type }}">
  <i class="fa fa-{{ type }}" aria-hidden="true"></i>&nbsp;
  {{ 'Share on /social/' | translate : { social: social } }}
</button>
<ng-template #inChoice>
  <button class="social-login-btn {{ type }}" (click)="openImageSelect()">
    <i class="fa fa-{{ type }}" aria-hidden="true"></i>
    {{ 'Share on /social/' | translate : { social: social } }}
  </button>
</ng-template>
